  dt {
    float: left;
    clear: left;
    width: 100px;
    text-align: left;
    font-weight: bold;
  }
  dt::after {
    content: ":";
  }
  dd {
    margin: 0 0 0 110px;
  }